import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-manchester-new-hampshire.png'
import image0 from "../../images/cities/scale-model-of-zimmerman-house-in-manchester-new-hampshire.png"
import image1 from "../../images/cities/scale-model-of-lok'd-room-escapes-in-manchester-new-hampshire.png"
import image2 from "../../images/cities/scale-model-of-sheehan-basquil-park-in-manchester-new-hampshire.png"
import image3 from "../../images/cities/scale-model-of-currier-museum-of-art-in-manchester-new-hampshire.png"
import image4 from "../../images/cities/scale-model-of-see-science-center-in-manchester-new-hampshire.png"
import image5 from "../../images/cities/scale-model-of-manchester-historic-association-millyard-museum-in-manchester-new-hampshire.png"
import image6 from "../../images/cities/scale-model-of-cowabunga's-in-manchester-new-hampshire.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Manchester'
            state='New Hampshire'
            image={image}
            lat='42.9849'
            lon='-71.4441'
            attractions={ [{"name": "Zimmerman House", "vicinity": "150-120 Ash St, Manchester", "types": ["museum", "point_of_interest", "establishment"], "lat": 42.9978721, "lng": -71.45569089999998}, {"name": "LOK'd! Room Escapes", "vicinity": "750 E Industrial Park Dr b, Manchester", "types": ["point_of_interest", "establishment"], "lat": 42.982767, "lng": -71.409064}, {"name": "Sheehan-Basquil Park", "vicinity": "Manchester", "types": ["park", "point_of_interest", "establishment"], "lat": 42.9845702, "lng": -71.45182829999999}, {"name": "Currier Museum of Art", "vicinity": "150 Ash St, Manchester", "types": ["museum", "point_of_interest", "establishment"], "lat": 42.997729, "lng": -71.45560999999998}, {"name": "SEE Science Center", "vicinity": "200 Bedford St, Manchester", "types": ["museum", "point_of_interest", "establishment"], "lat": 42.9896738, "lng": -71.46793020000001}, {"name": "Manchester Historic Association Millyard Museum", "vicinity": "200 Bedford St, Manchester", "types": ["museum", "point_of_interest", "establishment"], "lat": 42.9896065, "lng": -71.46808809999999}, {"name": "Cowabunga's!", "vicinity": "1328 Hooksett Rd, Hooksett", "types": ["point_of_interest", "establishment"], "lat": 43.0555171, "lng": -71.4435365}] }
            attractionImages={ {"Zimmerman House":image0,"LOK'd! Room Escapes":image1,"Sheehan-Basquil Park":image2,"Currier Museum of Art":image3,"SEE Science Center":image4,"Manchester Historic Association Millyard Museum":image5,"Cowabunga's!":image6,} }
       />);
  }
}